<template>
  <div id="reference-2">
    <div id="container-reference-2">
      <canvas id="canvas-reference-2"></canvas>
    </div>
    <div id="container-reference-2-overlay">
      <!-- <pulse-loader
        id="pulseLoader"
        :loading="loading"
        :color="color"
      ></pulse-loader> -->
      <div id="ref2-menu-flex" class="left-top">
        <div class="flex-column scale">
          <b-button
            variant="primary"
            id="back"
            class="rounded-circle px-2"
            @click="routerGoBack()"
          >
            <b-icon icon="chevron-left" scale="1"></b-icon>
          </b-button>

          <b-button
            variant="primary"
            id="fullscreen"
            class="rounded-circle px-2"
            @click="toggleFullscreen()"
          >
            <b-icon
              v-if="!fullscreenState"
              icon="fullscreen"
              scale="1"
            ></b-icon>
            <b-icon
              v-if="fullscreenState"
              icon="fullscreen-exit"
              scale="1"
            ></b-icon>
          </b-button>
          <img src="img/360_icon.webp" class="icon360" alt="" />
        </div>

        <!-- navigation on mobile -->
        <div id="camButtons" class="left-bottom hidden" v-show="isMobile">
          <b-button
            v-for="(item, index) in buttonLabels"
            :key="index"
            id="camButton"
            variant="primary"
            @click="moveCam(index)"
            class="fixed-width"
            :pressed.sync="buttonStates[index]"
            >{{ item }}</b-button
          >
        </div>
      </div>

      <!-- Backdrop -->
      <div id="backdrop"></div>

      <!-- Desktop Hint -->
      <!-- <div id="hint">
        <div id="hint-text">
          <p>
            {{ mobileHint }}
          </p>
        </div>
        <div id="hint-progress"></div>
      </div> -->

      <!-- Hotspots // navigation on desktop -->
      <b-button
        id="Home"
        v-show="!isMobile"
        variant="primary"
        @click="moveCam(0)"
        class="fixed-width left-bottom2 button"
        :pressed.sync="buttonStates[0]"
        >{{ buttonLabels[0] }}</b-button
      >
      <div
        class="hotspoticon button hotspot-button"
        v-for="(item, index) in hotspotLabels"
        :key="index"
        v-show="!isMobile"
        :id="'Hotspot0' + index"
      >
        <img
          src="img/hotspot_ring_aussen_004a4f.webp"
          class="hotspoticon spinCw"
          alt=""
          @click="moveCam(index + 1)"
          v-show="!buttonStates[index + 1]"
        />
        <img
          src="img/hotspot_ring_innen_00ffd6.webp"
          class="hotspoticon spinCcw"
          alt=""
          @click="moveCam(index + 1)"
          v-show="!buttonStates[index + 1]"
        />
        <div
          id="hotspotlabel"
          v-show="!buttonStates[index + 1]"
          @click="moveCam(index + 1)"
        >
          {{ item }}
        </div>
        <!-- </div> -->
      </div>

      <div id="custom-sidebar">
        <!-- open button -->
        <img
          v-if="!open"
          src="img/pfeil_l.webp"
          id="sidebar-toggle"
          @click="toggleOpen()"
        />
        <img
          v-if="open"
          src="img/pfeil_r.webp"
          id="sidebar-toggle"
          @click="toggleOpen()"
        />
        <img src="img/dreieck_links.webp" alt="" />
        <!-- content area -->
        <div id="sidebar-bg" class="sidebar-bg-img">
          <div id="sidebar-title">
            <h2>{{ currentSidemenuContent.title }}</h2>
            <h4>{{ currentSidemenuContent.subtitle }}</h4>
          </div>
          <div id="sidebar-body">
            <div id="sidebar-headline">
              <h5>{{ currentSidemenuContent.headline }}</h5>
              <h6>{{ currentSidemenuContent.subheadline }}</h6>
            </div>
            <div
              id="sidebar-textbox"
              v-if="currentSidemenuContent.textbox.length"
              v-html="currentSidemenuContent.textbox"
            ></div>
            <div
              id="sidebar-media-caroussel"
              v-if="currentSidemenuContent.media.length"
            >
              <!-- multiple slides -->
              <b-carousel
                id="carousel-2"
                ref="carousel2"
                v-if="currentSidemenuContent.media.length > 1"
                v-model="currentCarouselSlide"
                :interval="10000"
                img-width="1024"
                img-height="480"
                style="text-shadow: 1px 1px 2px #333"
                controls
                @sliding-start="onSlideStart"
                @sliding-end="onSlideEnd"
              >
                <!-- Text slides with image -->
                <b-carousel-slide
                  v-for="item in currentSidemenuContent.media"
                  :key="item.id"
                >
                  <div v-if="item.link">
                    <router-link id="slidelink" :to="item.link">
                      <!-- <b-embed
                        id="video-slide"
                        v-if="item.isVideo"
                        type="video"
                        aspect="16by9"
                        controls
                        :src="item.url"
                      /> -->
                      <video
                        controls
                        id="video-slide2"
                        v-if="item.isVideo"
                        poster="img/future_cabin.jpg"
                        autobuffer
                        loop
                      >
                        <source :src="item.url" type="video/mp4" />
                        Sorry, your browser doesn't support embedded videos.
                      </video>
                      <b-img v-if="!item.isVideo" :src="item.url" alt="" />
                    </router-link>
                  </div>
                  <div v-else>
                    <!-- <b-embed
                      id="video-slide"
                      v-if="item.isVideo"
                      type="video"
                      aspect="16by9"
                      controls
                      :src="item.url"
                    /> -->
                    <video
                      controls
                      id="video-slide2"
                      v-if="item.isVideo"
                      poster="img/future_cabin.jpg"
                      autobuffer
                      loop
                    >
                      <source :src="item.url" type="video/mp4" />
                      Sorry, your browser doesn't support embedded videos.
                    </video>
                    <b-img v-if="!item.isVideo" :src="item.url" alt="" />
                  </div>
                </b-carousel-slide>
              </b-carousel>
              <!-- one slide -->
              <b-carousel
                id="carousel-2"
                ref="carousel2"
                v-else
                v-model="currentCarouselSlide"
                :interval="10000"
                img-width="1024"
                img-height="480"
                style="text-shadow: 1px 1px 2px #333"
              >
                <!-- Text slides with image -->
                <b-carousel-slide
                  v-for="item in currentSidemenuContent.media"
                  :key="item.id"
                >
                  <div v-if="item.link">
                    <router-link id="slidelink" :to="item.link">
                      <b-embed
                        id="video-slide2"
                        v-if="item.isVideo"
                        type="video"
                        aspect="16by9"
                        controls
                        :src="item.url"
                      />
                      <b-img v-if="!item.isVideo" :src="item.url" alt="" />
                    </router-link>
                  </div>
                  <div v-else>
                    <b-embed
                      id="video-slide2"
                      v-if="item.isVideo"
                      type="video"
                      aspect="16by9"
                      controls
                      :src="item.url"
                    />
                    <b-img v-if="!item.isVideo" :src="item.url" alt="" />
                  </div>
                </b-carousel-slide>
              </b-carousel>
              <div id="sidebar-footer">
                <h5>
                  {{ currentSidemenuContent.media[currentCarouselSlide].title }}
                </h5>
                <h6>
                  {{
                    currentSidemenuContent.media[currentCarouselSlide]
                      .description
                  }}
                </h6>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Loading Screen -->
      <ref-loading-screen
        class="loadingscreen-container"
        :image="loadingScreen.image"
        :progresslabel="loadingScreen.progresslabel"
        :progressvalue="loadingScreen.progressvalue"
        :progressmax="loadingScreen.progressmax"
        :headline="loadingScreen.headline"
        :subline="loadingScreen.subline"
        :mobileHint="loadingScreen.mobileHint"
        :ismobile="isMobile"
        :referenceid="loadingScreen.referenceid"
        :loadingcompleted="loadingScreen.loadingcompleted"
      ></ref-loading-screen>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
// import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import store from '../store'
import RefLoadingScreen from '../components/Ref_LoadingScreen.vue'

export default {
  name: 'Reference2',
  data () {
    return {
      fullscreenState: false,
      isMobile: false,
      selected: '',
      color: '#00ffd6',
      loading: true,
      activated: false,
      open: false,
      isMobile: false,
      buttonLabels: [],
      buttonStates: [],
      hotspotLabels: [],
      hasTextbox: false,
      sidemenuContent: [],
      currentCarouselSlide: 0,
      currentSidemenuContentId: 0,
      currentSidemenuContent: {
        title: '',
        subtitle: '',
        headline: '',
        subheadline: '',
        textbox: '',
        media: []
      },
      loadingScreen: {
        image: '',
        progresslabel: '',
        progressvalue: 0,
        progressmax: 1,
        headline: '',
        subline: '',
        mobileHint: '',
        referenceid: '',
        loadingcompleted: false
      }
    }
  },
  created () {
    // console.log("ref1 created");
  },
  mounted () {
    window.addEventListener(
      'ref2_loadingprogress',
      (e) => {
        this.loadingScreen.progressvalue = e.detail

        if (e.detail === 1) {
          var that = this
          setTimeout(function () {
            that.loadingScreen.loadingcompleted = true
          }, 1000)
        }
      },
      false
    )
    window.addEventListener(
      'ref2_modelLoaded',
      (e) => {
        // console.log("MODEL LOADED" + e);
        // document.getElementById("pulseLoader").classList.add("hidden");

        document.getElementById('camButtons').classList.remove('hidden')

        document.getElementById('Hotspot00').classList.remove('hidden')
        document.getElementById('Hotspot01').classList.remove('hidden')
        document.getElementById('Hotspot02').classList.remove('hidden')
        document.getElementById('Hotspot03').classList.remove('hidden')
        this.loading = false
      },
      false
    )
    window.addEventListener(
      'Ref2_hotspotPositionChanged',
      (e) => {
        // console.log("HOTSPOT " + e.detail.id + " CHANGED - x: " + e.detail.x + ", y: " + e.detail.y );
        if (this.activated) {
          if (e.detail.id === '01') {
            if (e.detail.visible) {
              document.getElementById('Hotspot00').classList.remove('hidden')
            } else {
              document.getElementById('Hotspot00').classList.add('hidden')
            }
            document.getElementById('Hotspot00').style.top = e.detail.y + 'px'
            document.getElementById('Hotspot00').style.left = e.detail.x + 'px'
          }
          if (e.detail.id === '04') {
            if (e.detail.visible) {
              document.getElementById('Hotspot01').classList.remove('hidden')
            } else {
              document.getElementById('Hotspot01').classList.add('hidden')
            }
            document.getElementById('Hotspot01').style.top = e.detail.y + 'px'
            document.getElementById('Hotspot01').style.left = e.detail.x + 'px'
          }
          if (e.detail.id === '03') {
            if (e.detail.visible) {
              document.getElementById('Hotspot02').classList.remove('hidden')
            } else {
              document.getElementById('Hotspot02').classList.add('hidden')
            }
            document.getElementById('Hotspot02').style.top = e.detail.y + 'px'
            document.getElementById('Hotspot02').style.left = e.detail.x + 'px'
          }
          if (e.detail.id === '02') {
            // console.log(e.detail.visible)
            if (e.detail.visible) {
              document.getElementById('Hotspot03').classList.remove('hidden')
            } else {
              document.getElementById('Hotspot03').classList.add('hidden')
            }
            document.getElementById('Hotspot03').style.top = e.detail.y + 'px'
            document.getElementById('Hotspot03').style.left = e.detail.x + 'px'

            // console.log("HOTSPOT " + e.detail.id + " CHANGED - x: " + e.detail.x + ", y: " + e.detail.y );
          }
        }
      },
      false
    )
    window.addEventListener(
      'ref2_LoadingScreenClosed',
      (e) => {
        if (this.activated) {
          document.getElementById('backdrop').classList.add('hidden')
        }
      },
      false
    )

    this.changeData(require('@/data/content_reference2.json'))
    this.setSidemenuContent(this.currentSidemenuContentId)
    document
      .getElementById('custom-sidebar')
      .addEventListener('transitionend', () => {
        this.sidebarTransitionEnded()
      })
    document
      .getElementById('custom-sidebar')
      .addEventListener('transitioncancel', () => {
        // console.log("Transition canceld");
      })

    if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent) ||
    /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(navigator.userAgent.substr(0, 4)) ||
    navigator.userAgent.toLowerCase().match(/mobile/i)) {
      // console.log("MOBIL")
      this.isMobile = true
    } else {
      // console.log("DESKTOP")
      this.isMobile = false
    }

    this.script = require('@/scripts/reference2_3dcontent.js')
    this.script.main(this.isMobile)
  },
  activated () {
    // console.log('ref2 activated')
    this.activated = true
    const that = this

    const event = new Event('Ref2_routeActivated')
    window.dispatchEvent(event)
    // console.log('activate, open: ', this.open)

    // TODO AHHHHHHHHHHH!!!!
    this.toggleOpen()
    this.toggleOpen()
    this.moveCam(0)

    this.keepalive = this.script

    // setTimeout(function () {
    //   if (that.activated) {
    //     document.getElementById("hint").classList.add("fadeout");
    //   }
    // }, 7000);
    // setTimeout(function () {
    //   if (that.activated) {
    //     document.getElementById("hint-progress").classList.add("scaledown");
    //   }
    // }, 2000);
  },
  deactivated () {
    // console.log('ref2 deactivated')
    const event = new Event('Ref2_routeDeactivated')
    window.dispatchEvent(event)
    this.activated = false

    this.keepalive = this.script
  },
  components: {
    RefLoadingScreen
  },
  methods: {
    toggleFullscreen () {
      this.fullscreenState = !this.fullscreenState

      if (this.fullscreenState) {
        document.documentElement.requestFullscreen()
      } else {
        document.exitFullscreen()
      }
    },
    routerGoBack () {
      if (this.fullscreenState) {
        this.toggleFullscreen()
      }
      // this.$router.go(-1);
      this.$router.push({ path: '/#' + store.state.activeHomeViewHash })
    },
    setSidemenuContent (id) {
      // console.log('set side menu content, id: ', id)
      this.currentSidemenuContent = this.sidemenuContent[id]
      // if (this.currentSidemenuContent.media.length > 1) {
      //   document.getElementById("carousel-2___BV_inner_").classList.add("controls");
      // } else {
      //   document.getElementById("carousel-2___BV_inner_").classList.remove("controls");
      // }

      // TODO check if ref is existing!!!!!!!!!!!!!
      if (this.$refs.carousel2) {
        this.$refs.carousel2.setSlide(0)
        // var $this;
        // $this = document.getElementById("carousel-2");
        // if (this.currentSidemenuContent.media.length > 1) {
        //   $this.children(".carousel-control").show();
        // } else {
        //   $this.children(".carousel-control").hide();
        // }
      }
    },
    moveCam (targetid) {
      const event = new CustomEvent('startCamTween', {
        detail: targetid
      })
      for (let i = 0; i < this.buttonStates.length; i++) {
        if (i === targetid) {
          this.buttonStates[i] = true
        } else {
          this.buttonStates[i] = false
        }
      }

      window.dispatchEvent(event)
      if (targetid < this.sidemenuContent.length) {
        this.currentSidemenuContentId = targetid
      }
      if (this.open) {
        this.toggleOpen()
        // console.log('move cam open')
      } else {
        this.setSidemenuContent(this.currentSidemenuContentId)
        // console.log('move cam closed')
        var anim = document.getElementById('sidebar-toggle')
        anim.style.animationName = 'none'
        setTimeout(function () {
          anim.style.animationName = ''
        }, 10)
      }
    },
    sidebarTransitionEnded () {
      // console.log("sidebar transition ended");
      if (!this.open) {
        // console.log("is open: ", this.open);
        this.setSidemenuContent(this.currentSidemenuContentId)
        var anim = document.getElementById('sidebar-toggle')
        anim.style.animationName = 'none'
        setTimeout(function () {
          anim.style.animationName = ''
        }, 10)
      }
    },
    toggleOpen () {
      this.open = !this.open

      // console.log(this.open);
      if (this.open) {
        document.getElementById('custom-sidebar').classList.add('opened')
        document.getElementById('sidebar-bg').classList.add('opened')
        var anim = document.getElementById('sidebar-toggle')
        anim.style.animationName = 'none'
        document.getElementById('camButtons').classList.add('moveout')
      } else {
        document.getElementById('custom-sidebar').classList.remove('opened')
        document.getElementById('sidebar-bg').classList.remove('opened')
        document.getElementById('camButtons').classList.remove('moveout')
      }
    },
    onSlideStart (currentCarouselSlide) {
      if (!this.slides[currentCarouselSlide].isVideo) {
        // console.log("stop");
        var video = document.getElementById('video-slide2')
        if (video) {
          video.pause()
          video.currentTime = 0
        }
      }
    },
    onSlideEnd (currentCarouselSlide) {
      if (this.slides[currentCarouselSlide].isVideo) {
        // console.log("start");
        var video = document.getElementById('video-slide2')
        if (video) {
          video.currentTime = 0
          video.play()
        }
      }
    },
    changeData: function (data) {
      // console.log(data.sidemenuContent)
      this.buttonLabels = data.buttonLabels
      this.hotspotLabels = data.hotspotLabels

      this.loadingScreen.image = data.loadingScreen.image
      this.loadingScreen.progresslabel = data.loadingScreen.progresslabel
      this.loadingScreen.headline = data.loadingScreen.headline
      this.loadingScreen.subline = data.loadingScreen.subline
      this.loadingScreen.mobileHint = data.loadingScreen.mobileHint
      this.loadingScreen.referenceid = data.loadingScreen.referenceid

      for (let k = 0; k < this.buttonLabels.length; k++) {
        this.buttonStates.push(false)
      }
      for (let i = 0; i < data.sidemenuContent.length; i++) {
        const item = data.sidemenuContent[i]
        // console.log(item)
        item.id = i
        for (let j = 0; j < data.sidemenuContent[i].media.length; j++) {
          // const media = data.sidemenuContent[i].media[j];
          item.media[j].id = j
          // item.media.push(media)
        }
        this.sidemenuContent.push(item)
      }
      // console.log(this.sidemenuContent)
    },
    nextContent () {
      this.currentSidemenuContentId++
      this.currentSidemenuContentId =
        this.currentSidemenuContentId >= this.sidemenuContent.length
          ? 0
          : this.currentSidemenuContentId
      this.setSidemenuContent(this.currentSidemenuContentId)
    }
  }
}
</script>
<style scoped lang="scss">
.loadingscreen-container {
  position: absolute;

  top: 50%;

  left: 50%;

  -moz-transform: translateX(-50%) translateY(-50%);

  -webkit-transform: translateX(-50%) translateY(-50%);

  transform: translateX(-50%) translateY(-50%);
}
#backdrop {
  width: 100%;
  height: 100%;
  top: 0;
  background-color: #000000bb;
  z-index: 1000;
  position: absolute;
  pointer-events: all;
}
#video-slide2 {
  z-index: 1000;
  pointer-events: all;
  max-width: 80% !important;
  max-height: calc(100vh - 152px);
  height: auto;
  margin: auto;
}
#ref2-menu-flex {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 76px);
}
.flex-column {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.icon360 {
  opacity: 0.5;
  width: 50px;
  height: auto;
  position: relative;
  margin-top: 5px;
  margin-bottom: 5px;
}
.hotspoticon {
  width: 7vh;
  height: auto;
  position: absolute;
  top: -50%;
  left: -50%;
}
#hotspotlabel {
  left: 30px;
  top: 10px;
  position: relative;
  background-color: #004a4f98;
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
  width: max-content;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome and Opera */
}
.hotspot {
  position: absolute;
  pointer-events: auto;
}
.button {
  pointer-events: auto;
}
// #hint {
//   bottom: 300px;
//   margin-left: 40px;
//   margin-right: 40px;
//   max-width: 350px;
//   line-height: 1;
//   position: absolute;
//   background-color: rgba(0, 6, 12, 0.6);
//   opacity: 1;
//   display: none;
// }
// #hint-text {
//   padding: 20px;
//   padding-bottom: 2px;
// }
// #hint-progress {
//   background-color: #00ffd6;
//   height: 5px;
//   width: 100%;
// }
// #hint-progress.scaledown {
//   width: 0.1%;
//   transition: 5s;
//   -webkit-transition: 5s;
//   -moz-transition: 5s;
//   -o-transition: 5s;
// }
// #hint.fadeout {
//   transition: 1s;
//   -webkit-transition: 1s;
//   -moz-transition: 1s;
//   -o-transition: 1s;
//   opacity: 0;
// }
@media (pointer: coarse) {
  #hint {
    display: block;
  }
}
h2 {
  text-align: left;
  line-height: 1;
}
h4 {
  font-style: italic;
  color: #00ffd6;
  text-align: left;
  line-height: 1;
}
h5 {
  text-align: left;
  line-height: 1;
}
h6 {
  text-align: left;
  line-height: 1;
}
#container-reference-2 {
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  overflow: hidden;
}
#container-reference-2-overlay {
  position: absolute;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 20;
  pointer-events: none;
  overflow: hidden;
  text-align: left;
}
#canvas-reference-2 {
  top: 76px;
  left: 0px;
  position: absolute;
}
// #pulseLoader {
//   position: absolute;
//   top: 40%;
//   left: 50%;
//   z-index: 25;
// }
#fullscreen,
#back,
#camButton {
  pointer-events: auto;
}
#fullscreen {
  margin-top: 10px;
}
#camButtons {
  transform: translateX(0) scale(1);
  transform-origin: top;
  opacity: 1;
  transition: 1s;
  -webkit-transition: 1s;
  -moz-transition: 1s;
  -o-transition: 1s;
}
#camButtons.moveout {
  transform: translateX(-200px) scale(1);
  transform-origin: top;
  opacity: 0;
  transition: 1s;
  -webkit-transition: 1s;
  -moz-transition: 1s;
  -o-transition: 1s;
}
#custom-sidebar {
  height: calc(100vh - 76px);
  width: calc(50vw + ((100vh - 76px) * (123 / 1080)));
  top: 76px;
  position: relative;
  transform: translate(calc(100vw - ((100vh - 76px) * (123 / 1080))));
  transition: 1s;
  -webkit-transition: 1s;
  -moz-transition: 1s;
  -o-transition: 1s;
  z-index: 900;
  display: flex;
  pointer-events: all;
}
#custom-sidebar.opened {
  transform: translate(calc(100vw - 50vw - ((100vh - 76px) * (123 / 1080))));

  transition: 1s;
  -webkit-transition: 1s;
  -moz-transition: 1s;
  -o-transition: 1s;
}
#sidebar-bg {
  width: 50vw;
  overflow-y: auto;
}
#sidebar-title {
  background: rgba($color: #000000, $alpha: 0.5);
  padding-left: 8%;
  padding-right: 8%;
  padding-top: 4%;
  padding-bottom: 3%;
  margin-left: 10px;
}
#sidebar-body {
  margin-left: 10px;
}
#sidebar-footer,
#sidebar-headline {
  padding-left: 8%;
  padding-right: 8%;
  padding-top: 4%;
  padding-bottom: 3%;
}
#sidebar-textbox {
  background: rgba($color: #000000, $alpha: 0.5);
  padding-left: 8%;
  padding-right: 8%;
  padding-top: 5%;
  padding-bottom: 5%;
}
.sidebar-bg-img {
  background-image: url("../assets/textures/backgroundpixel.png");
}
#sidebar-toggle {
  z-index: 15;
  bottom: 0;
  position: absolute;
  pointer-events: all;
  height: 70px;
  left: 7px;
  animation-name: callForInteraction;
  animation-duration: 0.4s;
  animation-iteration-count: 6;
  animation-direction: alternate;
  animation-timing-function: ease-in-out;
  animation-delay: 5s;
}
.hotspot-button:hover,
#sidebar-toggle:hover {
  opacity: 0.5;
}
.hotspot-button:active,
#sidebar-toggle:active {
  transform: scale(0.95);
}
@keyframes callForInteraction {
  from {
    left: 7px;
  }
  to {
    left: -35px;
  }
}
@keyframes spinningCw {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.spinCw {
  animation-name: spinningCw;
  animation-duration: 7s;
  animation-iteration-count: infinite;
  /* linear | ease | ease-in | ease-out | ease-in-out */
  animation-timing-function: linear;
}
@keyframes spinningCcw {
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0deg);
  }
}
.spinCcw {
  animation-name: spinningCcw;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  /* linear | ease | ease-in | ease-out | ease-in-out */
  animation-timing-function: linear;
}
.hidden {
  display: none;
}
.left-top {
  position: absolute;
  top: 100px;
  left: 40px;
  z-index: 25;
}
.left-bottom {
  position: absolute;
  bottom: 40px;
  left: 0px;
  width: 150px;
}
.left-bottom2 {
  position: absolute;
  bottom: 40px;
  left: 40px;
  width: 150px;
}
.fixed-width {
  width: 150px !important;
  margin-bottom: 10px;
}
.btn-primary {
  color: black;
  background-color: #00ffd6;
  border-color: #00ffd6;
}
.btn-primary:hover {
  color: #212529;
  background-color: #00977e;
  border-color: #00977e;
}
.btn-primary:focus,
.btn-primary.focus {
  box-shadow: none;
}
.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  color: #212529;
  background-color: #00977e;
  border-color: #00977e;
}
.btn-primary:not(:disabled):not(.disabled):active:focus,
.btn-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem #00977e77;
}
@media (max-device-width: 600px) {
  #sidebar-bg {
    width: 80vw;
  }
  #custom-sidebar.opened {
    transform: translate(calc(100vw - 80vw - ((100vh - 76px) * (123 / 1080))));
    // width: calc(80vw + ((100vh - 76px) * (123 / 1080)));
    transition: 1s;
    -webkit-transition: 1s;
    -moz-transition: 1s;
    -o-transition: 1s;
  }
  #custom-sidebar {
    width: calc(80vw + ((100vh - 76px) * (123 / 1080)));
  }
}
@media (max-device-height: 600px) {
  #ref2-menu-flex {
    flex-direction: row;
  }
  .scale {
    transform: scale(0.8);
    transform-origin: left top;
  }
  #camButtons {
    transform: translateX(0) scale(0.8);
    transform-origin: left top;
  }
  .left-bottom {
    bottom: unset;
    left: 60px;
  }
}
@media (max-device-height: 400px) {
  #ref2-menu-flex {
    flex-direction: row;
  }
  .scale {
    transform: scale(0.6);
    transform-origin: left top;
  }
  #camButtons {
    transform: translateX(0) scale(0.6);
    transform-origin: left top;
  }
  .left-bottom {
    bottom: unset;
    left: 60px;
  }
}
@media (max-device-width: 400px) {
  // #ref2-menu-flex {
  //   transform: scale(0.6);
  //   transform-origin: top;
  // }
  .scale {
    transform: scale(0.6);
    transform-origin: left top;
  }

  #camButtons {
    transform: translateX(0) scale(0.6);
    transform-origin: left bottom;
  }
}
</style>
