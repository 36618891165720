/* jshint esversion: 6 */

import * as THREE from 'three/build/three.module'
import {
  GLTFLoader
} from 'three/examples/jsm/loaders/GLTFLoader'
import {
  OrbitControls
} from 'three/examples/jsm/controls/OrbitControls'

import Stats from 'three/examples/jsm/libs/stats.module'

import {
  RoughnessMipmapper
} from 'three/examples/jsm/utils/RoughnessMipmapper'

import {
  RGBELoader
} from 'three/examples/jsm/loaders/RGBELoader'

import TWEEN from '@tweenjs/tween.js'

import {
  EffectComposer
} from 'three/examples/jsm/postprocessing/EffectComposer.js'
import {
  RenderPass
} from 'three/examples/jsm/postprocessing/RenderPass.js'
import {
  UnrealBloomPass
} from 'three/examples/jsm/postprocessing/UnrealBloomPass.js'
import {
  BloomPass
} from 'three/examples/jsm/postprocessing/BloomPass.js'
import {
  FXAAShader
} from 'three/examples/jsm/shaders/FXAAShader.js'
import {
  ShaderPass
} from 'three/examples/jsm/postprocessing/ShaderPass.js'

/// /////////////////////////
//
// keep your hands off everything above
//
// Setup model below
//
/// /////////////////////////

/* model name & path */
const modelname = '3dmodels/reference_2_model/messe.glb'

/* light & shadow setup */
const useLights = false
let useShadows = true

/* background setup: color names or hex codes (0xff0000) -> red */
const backgroundColor = 'black'

/* hdr / environment setup */
const environmentName = 'img/hdr/reference2_environment.hdr'
const useEnvironment = true
const showEnvironment = false
const exposure = 1

/* debug helper */
// let logModelChildNames = true;
const useStats = false

/* post processe */
const usePostProcesses = false

/* uv animation */
const useUvAni = true

/// /////////////////////////
//
// Setup model above
//
// keep your hands off everything that follows below
//
/// /////////////////////////

let scene, camera, renderer, stats, mixer, clock, pmremGenerator, controls, composer, effectFXAA

let canvas, container
let routeActivated = false

const hotspotObjects = []
const hotspotLastStateObjects = []
const modelUVs = []

let modelLoaded = false
const menuOffset = 76

let widthHalf
let heightHalf

// let orbitChanged = true;

function setUpRender () {
  canvas = document.querySelector('#canvas-reference-2')
  container = document.querySelector('#container-reference-2')
  canvas.width = container.offsetWidth
  canvas.height = container.offsetHeight - menuOffset

  widthHalf = 0.5 * canvas.width
  heightHalf = 0.5 * canvas.height

  renderer = new THREE.WebGLRenderer({
    canvas,
    antialias: true,
    alpha: true
  })

  renderer.autoClear = false

  if (useShadows) {
    renderer.shadowMap.enabled = true
    // renderer.shadowMap.type = THREE.PCFSoftShadowMap;
  }
  renderer.setSize(canvas.width, canvas.height)

  pmremGenerator = new THREE.PMREMGenerator(renderer)
  pmremGenerator.compileEquirectangularShader()

  renderer.toneMapping = THREE.NoToneMapping
  renderer.toneMappingExposure = exposure

  renderer.outputEncoding = THREE.sRGBEncoding

  if (useStats) {
    stats = new Stats()
    container.appendChild(stats.dom)
  }
};

async function setupUpPostProcessing () {
  composer = new EffectComposer(renderer)

  composer.addPass(new RenderPass(scene, camera))

  const bloomPass = new UnrealBloomPass(new THREE.Vector2(window.innerWidth * 4, 4 * window.innerHeight), 1.5, 0.4, 0.85)
  // const bloomPass = new BloomPass(1, 25, 4.0, 1920);

  bloomPass.strength = 0.5
  bloomPass.radius = 0.15
  bloomPass.threshold = 0.25

  composer.addPass(bloomPass)

  // effectFXAA = new ShaderPass(FXAAShader);
  // effectFXAA.uniforms['resolution'].value.set(1 / window.innerWidth, 1 / window.innerHeight);
  // composer.addPass(effectFXAA);
}

function setUpCamera () {
  const fov = 45
  const aspect = canvas.width / canvas.height
  const near = 0.1
  const far = 100

  camera = new THREE.PerspectiveCamera(fov, aspect, near, far)
  camera.position.x = 0
  camera.position.y = 10.1
  camera.position.z = 0
  // camera.lookAt (new THREE.Vector3(3,200,30));
}

function setUpThreeScene () {
  scene = new THREE.Scene()
  scene.background = new THREE.Color(backgroundColor)
  // const color = 0xff0000;
  // const density = 0.1;
  // scene.fog = new THREE.FogExp2(color, density);
  scene.add(camera)
  // scene.add(textureCamera);

  //   const axesHelper = new THREE.AxesHelper( 5 );
  // scene.add( axesHelper );
};

function setUpLight () {
  const hlight = new THREE.AmbientLight(0x404040, 100)
  scene.add(hlight)

  const directionalLight = new THREE.DirectionalLight(0xffffff, 100)
  directionalLight.position.set(0, 1, 0)
  directionalLight.castShadow = true
  scene.add(directionalLight)

  const light = new THREE.PointLight(0xc4c4c4, 10)
  light.position.set(0, 300, 500)
  scene.add(light)

  const light2 = new THREE.PointLight(0xc4c4c4, 10)
  light2.position.set(500, 100, 0)
  scene.add(light2)

  const light3 = new THREE.PointLight(0xc4c4c4, 10)
  light3.position.set(0, 100, -500)
  scene.add(light3)

  const light4 = new THREE.PointLight(0xc4c4c4, 10)
  light4.position.set(-500, 300, 500)
  scene.add(light4)
};

function setUpControls () {
  // const controls = new OrbitControls(camera, renderer.domElement);
  controls = new OrbitControls(camera, canvas)

  controls.enableZoom = false // Zooming
  controls.enablePan = false
  // controls.autoRotate = true;       // enable rotation

  controls.minPolarAngle = Math.PI / 3 // Limit angle of visibility
  controls.maxPolarAngle = Math.PI * 2 / 3 // Limit angle of visibility

  // controls.addEventListener('change', onOrbitChanged);
  controls.target.set(0, 10, 0)
  controls.update()

  return controls
}

function setUpEnvironment () {
  new RGBELoader()
    .setDataType(THREE.UnsignedByteType)
    .load(environmentName, function (texture) {
      var envMap = pmremGenerator.fromEquirectangular(texture).texture

      if (showEnvironment) {
        scene.background = envMap
      }
      if (useEnvironment) {
        scene.environment = envMap
      }

      texture.dispose()
      pmremGenerator.dispose()
    })
}

function addModelToScene () {
  modelLoaded = false
  let model
  const loader = new GLTFLoader()

  // A reusable function to set up the models. We're passing in a position parameter
  // so that they can be individually placed around the scene
  const onLoad = (gltf, position) => {
    // console.log('started model loading');

    // use of RoughnessMipmapper is optional
    var roughnessMipmapper = new RoughnessMipmapper(renderer)

    model = gltf.scene // .children[0];

    model.scale.set(1, 1, 1)
    model.position.copy(position)

    model.traverse(function (child) {
      // shadows
      if (child.isMesh) {
        roughnessMipmapper.generateMipmaps(child.material)

        if (useShadows) {
          if (child.name.includes('##receive')) {
            child.receiveShadow = true
            // console.log("RECEIVE - " + child.name);
          }
          if (child.name.includes('##cast')) {
            child.castShadow = true
            // console.log("CAST - " + child.name);
          }
        }
      } else if (child.isLight) {
        if (useShadows) {
          child.castShadow = true
        }
      }
      // hotspots
      if (child.name.includes('##hotspot-')) {
        const splits = child.name.split('##')
        const hotspot = {}
        const lastStateHotspot = {}

        splits.forEach(split => {
          // console.log("SPLIT - " + split);
          if (split.includes('hotspot-')) {
            const secSplits = split.split('-')
            if (secSplits.length > 1) {
              hotspot.id = secSplits[1]
              lastStateHotspot.id = hotspot.id
            }
          }
        })

        hotspot.name = splits[0]
        hotspot.object = child
        lastStateHotspot.x = hotspot.object.position.x
        lastStateHotspot.y = hotspot.object.position.y
        lastStateHotspot.z = hotspot.object.position.z

        hotspotObjects.push(hotspot)
        hotspotLastStateObjects.push(lastStateHotspot)
        // console.log(hotspot.object.position);
        // console.log(hotspot)
      }

      // uvs
      if (useUvAni && child.isMesh && child.name.includes('##uvani')) {
        const texture = child.material.map

        texture.repeat.set(1, 1)
        texture.center.set(0, 0)
        // texture.rotation = 0.5; // rotation is around [ 0.5, 0.5 ]

        // console.log(texture);

        modelUVs.push(texture)
      }
      // if (logModelChildNames) {
      // console.log(child.name);
      // }
    })

    scene.add(model)
    roughnessMipmapper.dispose()

    mixer = new THREE.AnimationMixer(model)
    gltf.animations.forEach((clip) => {
      mixer.clipAction(clip).play()
    })

    modelLoaded = true
    // console.log('finished model loading');

    const event = new Event('ref2_modelLoaded')

    window.dispatchEvent(event)

    hotspotObjects.forEach((hotspot) => {
      HotspotPositionChanged(hotspot)
    })
  }

  // the loader will report the loading progress to this function
  const onProgress = (progress) => {
    // console.log(progress.loaded / progress.total)

    const event = new CustomEvent('ref2_loadingprogress', {
      detail: progress.loaded / progress.total
    })

    window.dispatchEvent(event)
  }

  // the loader will send any error messages to this function, and we'll log
  // them to to console
  const onError = (errorMessage) => {
    console.log(errorMessage)
  }

  // load the first model. Each model is loaded asynchronously,
  // so don't make any assumption about which one will finish loading first
  const modelPosition = new THREE.Vector3(0, 0, 0)

  loader.load(modelname, gltf => onLoad(gltf, modelPosition), onProgress, onError)

  // if (hotspotObjects.length > 0) {
  // hotspotObjects.forEach((hotspot) => {
  //     HotspotPositionChanged(hotspot);

  // })
  // }

  return model
};

function onWindowResize () {
  canvas.width = container.offsetWidth
  canvas.height = container.offsetHeight - menuOffset

  widthHalf = 0.5 * canvas.width
  heightHalf = 0.5 * canvas.height

  camera.aspect = canvas.width / canvas.height

  camera.updateProjectionMatrix()

  renderer.setSize(canvas.width, canvas.height)
}

function onRouteActivated () {
  if (controls) {
    controls.reset()
  }
  routeActivated = true
  // console.log('ref 2 activated');
  requestAnimationFrame(render)
  onWindowResize()
}

function onRouteDeactivated () {
  routeActivated = false
  // console.log('ref 2 deactivated');
}

let camPosTween
let controlPosTween
let camPosResetTween
let controlPosResetTween
const controlsTweening = false

function setUpTweens () {
  camPosTween = new TWEEN.Tween(camera.position)
    .easing(TWEEN.Easing.Cubic.InOut)
    .interpolation(TWEEN.Interpolation.CatmullRom)
  controlPosTween = new TWEEN.Tween(controls.target)
    .easing(TWEEN.Easing.Cubic.InOut)
    .interpolation(TWEEN.Interpolation.CatmullRom)
    .onUpdate(function () {
      controls.update()
    })
    .onStart(function () {
      controls.minPolarAngle = 0 // Limit angle of visibility
      controls.maxPolarAngle = Math.PI * 2 // Limit angle of visibility
    })
    .onStop(function () {
      controls.minPolarAngle = minControlAngle
      controls.maxPolarAngle = maxControlAngel
    })
    .onComplete(function () {
      controls.minPolarAngle = minControlAngle
      controls.maxPolarAngle = maxControlAngel
    })

  camPosResetTween = new TWEEN.Tween(camera.position)
    .easing(TWEEN.Easing.Cubic.InOut)
    .interpolation(TWEEN.Interpolation.CatmullRom)
  controlPosResetTween = new TWEEN.Tween(controls.target)
    .easing(TWEEN.Easing.Cubic.InOut)
    .interpolation(TWEEN.Interpolation.CatmullRom)
    .onUpdate(function () {
      controls.update()
    })
    .onStart(function () {
      controls.minPolarAngle = 0 // Limit angle of visibility
      controls.maxPolarAngle = Math.PI * 2 // Limit angle of visibility
    })
    .onStop(function () {
      controls.minPolarAngle = minControlAngle
      controls.maxPolarAngle = maxControlAngel
    })
    .onComplete(function () {
      controls.minPolarAngle = minControlAngle
      controls.maxPolarAngle = maxControlAngel
    })
}

let minControlAngle = Math.PI / 3
let maxControlAngel = Math.PI * 2 / 3

const camResetPosition = new THREE.Vector3(0, 10.1, 0)
const controlsResetPostion = new THREE.Vector3(0, 10, 0)

const camTargetPosition = new THREE.Vector3(0, 0, 0)
const controlsTargetPosition = new THREE.Vector3(0.1, 0, 0)

let lastTargetId = -1

function onStartCamTween (e) {
  // var camPos = new TWEEN.Tween(camera.position)
  // console.log('##### ', e.detail)
  camPosTween.stop()
  controlPosTween.stop()
  camPosResetTween.stop()
  controlPosResetTween.stop()

  minControlAngle = Math.PI / 3
  maxControlAngel = Math.PI * 2 / 3

  switch (e.detail) {
    case 0:
      camTargetPosition.set(20, 6, 2)
      controlsTargetPosition.set(6, 2, 0)

      camResetPosition.set(22.5, 5, 2)
      controlsResetPostion.set(6, 2, 0)

      minControlAngle = Math.PI / 6
      maxControlAngel = Math.PI / 2
      break

    case 1:
      camTargetPosition.set(17, 2.2, 1)
      controlsTargetPosition.set(16.9, 2.2, 1)

      camResetPosition.set(22.5, 5, 2)
      controlsResetPostion.set(6, 2, 0)
      break

    case 2:
      camTargetPosition.set(3, 2, -6)
      controlsTargetPosition.set(3, 1.996, -6.02)

      camResetPosition.set(22.5, 5, 2)
      controlsResetPostion.set(6, 2, 0)
      break

    case 3:
      camTargetPosition.set(-3.2, 2, -4.8)
      controlsTargetPosition.set(-3.3, 1.985, -4.9)

      camResetPosition.set(22.5, 5, 2)
      controlsResetPostion.set(6, 2, 0)
      break

    case 4:
      camTargetPosition.set(2.8, 2, 5)
      controlsTargetPosition.set(2.7, 1.95, 5.1)

      camResetPosition.set(22.5, 5, 2)
      controlsResetPostion.set(6, 2, 0)
      break

    default:
      camTargetPosition.set(22.5, 5, 2)
      controlsTargetPosition.set(6, 2, 0)

      camResetPosition.set(22.5, 5, 2)
      controlsResetPostion.set(6, 2, 0)
      break
  }

  setUpTweens()

  if (lastTargetId === 0) {
    camPosTween.to(camTargetPosition, 3000).start()
    controlPosTween.to(controlsTargetPosition, 3000).start()
  } else {
    camPosResetTween.to(camResetPosition, 2000).start().chain(camPosTween.to(camTargetPosition, 3000))
    controlPosResetTween.to(controlsResetPostion, 2000).start().chain(controlPosTween.to(controlsTargetPosition, 3000))
  }

  lastTargetId = e.detail
}
// function onOrbitChanged(){
//     orbitChanged = true;
// }

function HotspotPositionChanged (hotspot) {
  // TODO calc screenspace position

  const screenPosition = toScreenPosition(hotspot.object, camera)

  const event = new CustomEvent('Ref2_hotspotPositionChanged', {
    detail: {
      id: hotspot.id,
      visible: screenPosition.visible,
      x: screenPosition.x,
      y: screenPosition.y + menuOffset / 2
    }
  })

  window.dispatchEvent(event)
}

function toScreenPosition (obj, camera) {
  // if(obj.name === "Hotspot_meeting##hotspot-04"){
  //   console.log(obj.position)
  // }
  var vector = new THREE.Vector3()

  camera.updateMatrix() // make sure camera's local matrix is updated
  camera.updateMatrixWorld() // make sure camera's world matrix is updated
  camera.matrixWorldInverse.copy(camera.matrixWorld).invert()

  obj.updateMatrix() // make sure obj's local matrix is updated
  obj.updateMatrixWorld() // make sure obj's world matrix is updated

  var frustum = new THREE.Frustum()
  frustum.setFromProjectionMatrix(new THREE.Matrix4().multiplyMatrices(camera.projectionMatrix, camera.matrixWorldInverse))
  const visible = frustum.containsPoint(obj.position)

  camera.updateProjectionMatrix()
  vector.setFromMatrixPosition(obj.matrixWorld)
  vector.project(camera)

  vector.x = ((vector.x * widthHalf) + widthHalf)
  vector.y = (-(vector.y * heightHalf) + heightHalf)

  return {
    visible: visible,
    x: vector.x,
    y: vector.y
  }
};

let uvaniWait = false

function render (time) {
  time *= 0.001 // convert time to seconds
  //  console.log(time);
  var delta = clock.getDelta()

  if (mixer) mixer.update(delta)

  if (modelUVs.length > 0) {
    modelUVs.forEach(tex => {
      if (!uvaniWait) {
        tex.offset.set((tex.offset.x + 0.001) % 1, 0)
      }
      // console.log(tex.offset.x)
      if ((tex.offset.x >= 0.333 && tex.offset.x < 0.3331) ||
        (tex.offset.x >= 0.666 && tex.offset.x < 0.6661) ||
        (tex.offset.x >= 0.0 && tex.offset.x < 0.0001)) {
        uvaniWait = true
        setTimeout(function () {
          uvaniWait = false
        }, 3000)
      }
    })
  }

  if (useStats) {
    stats.update()
  }

  if (modelLoaded) {
    hotspotObjects.forEach((hotspot) => {
      hotspotLastStateObjects.forEach((lastStateHotspot) => {
        if (hotspot.id === lastStateHotspot.id) {
          // if (hotspot.object.position.x !== lastStateHotspot.x
          //     || hotspot.object.position.y !== lastStateHotspot.y
          //     || hotspot.object.position.z !== lastStateHotspot.z
          //     || orbitChanged) {

          // console.log("##");
          // console.log(hotspot.object.position.x + " - " + hotspot.object.position.y + " - " + hotspot.object.position.z);
          // console.log("xxx");
          // console.log(lastStateHotspot.x + " - " + lastStateHotspot.y + " - " + lastStateHotspot.z);

          HotspotPositionChanged(hotspot)

          lastStateHotspot.x = hotspot.object.position.x
          lastStateHotspot.y = hotspot.object.position.y
          lastStateHotspot.z = hotspot.object.position.z
          // }
        }
      })
      // console.log("HOTSPOT:  " + hotspot.name + " ID: " + hotspot.id + " POSITTION: " + hotspot.object.position);
    })
  }

  if (controlsTweening) {
    controls.update()
  }
  TWEEN.update()
  // orbitChanged = false;

  if (usePostProcesses) {
    composer.render()
  } else {
    renderer.render(scene, camera)
  }

  if (routeActivated) {
    requestAnimationFrame(render)
  }
};

export async function main (_isMobile) {
  clock = new THREE.Clock()

  // console.log("in scene 2 ", _isMobile)
  useShadows = !_isMobile
  setUpRender()

  setUpCamera()

  setUpThreeScene()

  if (useLights) {
    setUpLight()
  }
  setUpControls(camera, renderer)

  if (useEnvironment || showEnvironment) {
    setUpEnvironment()
  }

  if (usePostProcesses) {
    setupUpPostProcessing()
  }

  window.addEventListener('resize', onWindowResize, false)
  window.addEventListener('Ref2_routeActivated', onRouteActivated, false)
  window.addEventListener('Ref2_routeDeactivated', onRouteDeactivated, false)

  setUpTweens()
  window.addEventListener('startCamTween', function (e) {
    onStartCamTween(e)
  })
  await addModelToScene()

  // requestAnimationFrame(render)
}
